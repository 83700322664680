import React from "react"
import { Link } from 'gatsby'
import { Row } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../styles/404.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
      <Row>
      <div className="error-404"> 
            <h1>404</h1>
            <h2>Page not found</h2>
            <p><Link to="/">Return to homepage</Link></p> 
          </div>
      </Row>
      <Row>
        <div className="pic">
        </div>
      </Row>
  </Layout>
)

export default NotFoundPage
